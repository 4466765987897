import {
    Button,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
} from "@fluentui/react-components";
import { ProviderState, Providers } from "@microsoft/mgt-element";
import { DriveItem } from "@microsoft/microsoft-graph-types";
import { useEffect, useState } from "react";
import { DriveItemsResponse } from "../models/ms-graph";
import { pipeline } from "../models/pipeline";
import FileCustom from "./FileCustom";
import { canBeAnalyzed } from "./FileList";
import Loading from "./Loading";
import VirtualScroller from "./VirtualScroller";

export type DriveItemWithStatus = {
    driveItem: DriveItem;
    checked: boolean;
};

type Props = {
    startAnalysis: (files: DriveItem[]) => void;
    selectedItems: DriveItem[];
    setError: (err: string) => void;
    fetchPipelines: (itemIds: DriveItem[]) => Promise<pipeline[]>;
};

export default function FileListModalAnalyze({
    startAnalysis,
    selectedItems,
    setError,
    fetchPipelines,
}: Props) {
    const [files, setFiles] = useState<DriveItemWithStatus[]>([]);
    const [loading, setLoading] = useState(true);
    const [pipelines, setPipelines] = useState<pipeline[]>([]);

    useEffect(() => {
        fetchFiles();
    }, []);

    let nbFilesSelected = files.filter((file) => file.checked).length;

    const fetchFiles = async () => {
        setLoading(true);

        let { files, error } = await getFilesAndChildren(selectedItems);

        if (error) {
            setError(error.message);
            return;
        }

        let gotPipelines = await fetchPipelines(files);

        setPipelines(gotPipelines);

        let filesToAnalyze: DriveItemWithStatus[] = files
            .filter((item) => canBeAnalyzed(item, gotPipelines))
            .map((item) => ({
                checked: true,
                driveItem: item,
            }));

        setFiles(filesToAnalyze);

        setLoading(false);
    };

    const handleCheckChange = async (item: DriveItem, checked: boolean) => {
        setFiles(
            files.map((itemIn) => ({
                ...itemIn,
                checked:
                    itemIn.driveItem.id === item.id
                        ? !itemIn.checked
                        : itemIn.checked,
            }))
        );
    };

    const jsxFileList = (items: DriveItemWithStatus[]) =>
        items.map((item) => (
            <FileCustom
                file={item.driveItem}
                checked={item.checked}
                checkChange={handleCheckChange}
                onClick={() => {}}
                key={item.driveItem.id}
                pipeline={pipelines.find(
                    (pipeline) => pipeline.teamsId === item.driveItem.id
                )}
                selected={false}
            ></FileCustom>
        ));

    const handleStart = () => {
        startAnalysis(
            files.filter((file) => file.checked).map((el) => el.driveItem)
        );
    };

    return (
        <DialogSurface>
            <DialogBody>
                <DialogTitle>Analyze</DialogTitle>
                <DialogContent
                    style={{ marginBottom: "10px", marginTop: "10px" }}
                >
                    {loading ? (
                        <div style={{ height: "60px" }}>
                            <Loading />
                        </div>
                    ) : files.length ? (
                        <VirtualScroller
                            hasMore={false}
                            jsxItems={jsxFileList(files)}
                            loadNextPage={() => {}}
                            loadingMore={false}
                            nbLoadedFiled={files.length}
                        ></VirtualScroller>
                    ) : (
                        <div>No processable files selected</div>
                    )}
                </DialogContent>
                <DialogActions>
                    <DialogTrigger disableButtonEnhancement>
                        <Button appearance="secondary">Close</Button>
                    </DialogTrigger>
                    <Button
                        appearance="primary"
                        disabled={!nbFilesSelected}
                        onClick={handleStart}
                    >
                        Start Analysis{" "}
                        {nbFilesSelected ? <>({nbFilesSelected})</> : <></>}
                    </Button>
                </DialogActions>
            </DialogBody>
        </DialogSurface>
    );
}

export const getFilesAndChildren = async (itemsIn: DriveItem[]) => {
    let files: DriveItem[] = [];

    try {
        const filePromises = itemsIn.map(async (item) => {
            if (item.folder && item.id) {
                return recursivelyFetchFiles(item);
            } else {
                return [item];
            }
        });

        const results = await Promise.all(filePromises);

        files = results.flat();
    } catch (err: any) {
        if (err.statusCode === 401) {
            Providers.globalProvider.setState(ProviderState.SignedOut);
        }
        return { files: [], error: err.message };
    }

    return { files, error: null };
};

export const recursivelyFetchFiles = async (
    folder: DriveItem
): Promise<DriveItem[]> => {
    const driveID = folder.parentReference?.driveId;
    let nextUrl = `/drives/${driveID}/items/${folder.id}/children`;

    let items: DriveItem[] = [];

    while (nextUrl) {
        const response: DriveItemsResponse =
            await Providers.globalProvider.graph.client
                .api(nextUrl)
                .top(200)
                .get();
        items.push(...response.value);
        nextUrl = response["@odata.nextLink"] || "";
    }

    const folderPromises = items.map(async (item) => {
        if (item.folder && item.id) {
            return recursivelyFetchFiles(item);
        }
        return [item];
    });

    const folderResults = await Promise.all(folderPromises);

    return folderResults.flat();
};
